import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/loginScreen.vue'
import OTPLogin from '../views/otpLoginScreen'
//===============================================================================================primary
const home = () => import(/* webpackChunkName: "home" */ '../views/PrimaryScreens/Home.vue');
const debtorSearch = () => import(/* webpackChunkName: "selectStore" */ '../views/PrimaryScreens/accountsScreen.vue');
const auditOptions = () => import(/* webpackChunkName: "auditScreen" */ '../views/PrimaryScreens/auditScreen.vue');
const financeOptions = () => import(/* webpackChunkName: "financeScreen" */ '../views/PrimaryScreens/financeScreen.vue');
const selectStore = () => import(/* webpackChunkName: "selectStore" */ '../views/PrimaryScreens/selectStore.vue');
const salesOptions = () => import(/* webpackChunkName: "salesScreen" */ '../views/PrimaryScreens/salesScreen.vue');
const stockOptions = () => import(/* webpackChunkName: "stockScreen" */ '../views/PrimaryScreens/stockScreen.vue');
const supplierSearch = () => import(/* webpackChunkName: "supplierScreen" */ '../views/PrimaryScreens/supplierScreen.vue');
const supplierDetails = () => import(/* webpackChunkName: "supplierDetails" */ '../views/Accounts/Creditor/SupplierView.vue');
const reportOptions = () => import(/* webpackChunkName: "reportScreen" */ '../views/PrimaryScreens/ReportsScreen.vue');
//===============================================================================================accountsScreen
const accountsView = () => import(/* webpackChunkName: "accountView" */ '../views/Accounts/accountsView.vue');
const debtorView = () => import(/* webpackChunkName: "debtorView" */ '../views/Accounts/debtorView.vue');
//===============================================================================================Audits
const auditView = () => import(/* webpackChunkName: "auditView" */ '../views/Audit/auditView.vue');
const discountItem = () => import(/* webpackChunkName: "discountItemView" */ '../views/Audit/discountItemView.vue');
const discountSearch = () => import(/* webpackChunkName: "discountSearchView" */ '../views/Audit/discountSearchView.vue');
const refundItem = () => import(/* webpackChunkName: "refundItemView" */ '../views/Audit/refundItemView.vue');
const refundSearch = () => import(/* webpackChunkName: "refundSearchView" */ '../views/Audit/refundSearchView.vue');
const voidItem = () => import(/* webpackChunkName: "voidItemView" */ '../views/Audit/voidItemView.vue');
const voidSearch = () => import(/* webpackChunkName: "voidSearchView" */ '../views/Audit/voidSearchView.vue');
//===============================================================================================Settings
const selectDevice = () => import(/* webpackChunkName: "selectDevice" */ '../views/Settings/ComputerListView');
const settings = () => import(/* webpackChunkName: "settingsScreen" */ '../views/Settings/SettingsView');
const emails = () => import(/* webpackChunkName: "email-settings" */ '../views/Settings/EmailSetupView');
//===============================================================================================financeScreen
const bankView = () => import(/* webpackChunkName: "bankView" */ '../views/Finance/bankView.vue');
const cashView = () => import(/* webpackChunkName: "cashView" */ '../views/Finance/cashView.vue');
const financeView = () => import(/* webpackChunkName: "financeView" */ '../views/Finance/financeView.vue');
const payoutAll = () => import(/* webpackChunkName: "voidSearchView" */ '../views/Finance/payoutAllView.vue');
const payoutStore = () => import(/* webpackChunkName: "payoutStoreView" */ '../views/Finance/payoutStoreView.vue');
const payoutTill = () => import(/* webpackChunkName: "payoutTill" */ '../views/Finance/payoutTill.vue');
const safeSearch = () => import(/* webpackChunkName: "searchSafe" */ '../views/Finance/Safes/SearchSafes.vue');
const safeView = () => import(/* webpackChunkName: "viewSafe" */ '../views/Finance/Safes/safeView.vue');
//===============================================================================================salesScreen
const bestWorst = () => import(/* webpackChunkName: "BestWorstView" */ '../views/Sales/BestWorstView.vue');
const departments = () => import(/* webpackChunkName: "departmentView" */ '../views/Sales/departmentView.vue');
const saleCashier = () => import(/* webpackChunkName: "saleCashierView" */ '../views/Sales/saleCashierView.vue');
const saleSummary = () => import(/* webpackChunkName: "saleSummaryView" */ '../views/Sales/saleSummaryView.vue');
const kitchenOrders = () => import(/* webpackChunkName: "kitchenOrders" */ '../views/Sales/KitchenOrders/OrdersOverview');
const turnover = () => import(/* webpackChunkName: "turnoverView" */ '../views/Sales/turnoverView.vue');
const invoiceSearch = () => import(/* webpackChunkName: "invoiceSearch" */ '../views/Sales/InvoiceTable/searchInvoiceView.vue');
const invoiceView = () => import(/* webpackChunkName: "invoiceView" */ '../views/Sales/InvoiceTable/InvoiceView.vue');
//===============================================================================================stock
const belowMin = () => import(/* webpackChunkName: "belowMinView" */ '../views/Stock/belowMinView.vue');
const stockreport = () => import(/* webpackChunkName: "stockReportView" */ '../views/Stock/stockReportView.vue');
const stockSearch = () => import(/* webpackChunkName: "payoutTill" */ '../views/Stock/stockSearchView.vue');
const stockItem = () => import(/* webpackChunkName: "stockView" */ '../views/Stock/stockView.vue');
const stockTakesView = () => import(/* webpackChunkName: "stockView" */ '../views/Stock/StockTakesView');
//===============================================================================================warning
const warnings = () => import(/* webpackChunkName: "WarningView" */ '../views/Warning/warningView.vue');

//===============================================================================================warning
const securityGroups = () => import(/* webpackChunkName: "securityGroups" */ '../views/Security/SecurityGroupsView');
const selectSecurityType = () => import(/* webpackChunkName: "selectSecurityType" */ '../views/Security/SelectSecurityTypeView');
const updateSecurity = () => import(/* webpackChunkName: "updateSecurity" */ '../views/Security/UpdateSecurityView');

//===============================================================================================Reports
const cashUpReport = () => import(/* webpackChunkName: "CashUpReports" */ '../views/reports/Cashup/cashUpReportsScreen.vue');
//==========CashUP
const cashUpAcc = () => import(/* webpackChunkName: "cashUpAcc" */ '../views/reports/Cashup/reports/cashUpAccPayment.vue');
const cashUpCred = () => import(/* webpackChunkName: "cashUpCred" */ '../views/reports/Cashup/reports/cashUpCredit.vue');
const cashUpPay = () => import(/* webpackChunkName: "cashUpPay" */ '../views/reports/Cashup/reports/cashUpPayOut.vue');
const cashUpSale = () => import(/* webpackChunkName: "cashUpSale" */ '../views/reports/Cashup/reports/cashUpSales.vue');
const cashUpSafe = () => import(/* webpackChunkName: "cashUpSafe" */ '../views/reports/Cashup/reports/cashUpSafe.vue');
const cashUpVoids = () => import(/* webpackChunkName: "cashUpVoids" */ '../views/reports/Cashup/reports/cashUpVoids.vue');


const salesReport = () => import(/* webpackChunkName: "salesReports" */ '../views/reports/sales/salesReportsScreen.vue');
const stockReports = () => import(/* webpackChunkName: "CashUpReports" */ '../views/reports/StockReports/stockReportsScreen.vue');
const supervisorReports = () => import(/* webpackChunkName: "CashUpReports" */ '../views/reports/supervisor/supervisorReportsScreen.vue');

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'login',
        component: login
    },
    {
        path: '/OTPLogin',
        name: 'OTPLogin',
        component: OTPLogin
    },

    {
        path: '/stock',
        name: 'stock',
        component: stockItem
    },
    {
        path: '/stockSearchView',
        name: 'stockSearchView',
        component: stockSearch
    },
    {
        path: '/main',
        component: () => import("../layout/layout"),
        children: [
            /*===========================================PRIMARY================================================*/
            {
                path: '/home',
                name: 'home',
                component: home,
            },
            {
                path: '/accountsScreen',
                name: 'accountsScreen',
                component: debtorSearch
            },
            {
                path: '/audit',
                name: 'audit',
                component: auditOptions
            },
            {
                path: '/financeScreen',
                name: 'financeScreen',
                component: financeOptions
            },
            {
                path: '/selectStore',
                name: 'selectStore',
                component: selectStore
            },
            {
                path: '/salesScreen',
                name: 'salesScreen',
                component: salesOptions
            },
            {
                path: '/stockScreen',
                name: 'stockScreen',
                component: stockOptions
            },
            {
                path: '/supplier',
                name: 'supplierScreen',
                component: supplierSearch
            },
            {
                path: '/reports',
                name: 'reports',
                component: reportOptions
            },
            /*==============================================ACCOUNTS====================================================*/
            {
                path: '/accountsView',
                name: 'accountsView',
                component: accountsView
            }, {
                path: '/debtorViews',
                props: true,
                name: 'debtorView',
                component: debtorView
            },
            {
                path: '/supplier/:id',
                props: true,
                name: 'supplierDetails',
                component: supplierDetails
            },
            /*==============================================AUDITS====================================================*/
            {
                path: '/auditView',
                name: 'auditView',
                component: auditView
            },
            {
                path: '/discountItemView',
                name: 'discountItemView',
                component: discountItem
            },
            {
                path: '/discountSearchView',
                name: 'discountSearchView',
                component: discountSearch
            },
            {
                path: '/refundItemView',
                name: 'refundItemView',
                component: refundItem
            },
            {
                path: '/refundSearchView',
                name: 'refundSearchView',
                component: refundSearch
            },
            {
                path: '/voidItemView',
                name: 'voidItemView',
                component: voidItem
            },
            {
                path: '/voidSearchView',
                name: 'voidSearchView',
                component: voidSearch
            },
            /*==============================================FINANCE====================================================*/
            {
                path: '/bankView',
                name: 'bankView',
                component: bankView
            },
            {
                path: '/cashView',
                name: 'cashView',
                component: cashView
            },
            {
                path: '/financeView',
                name: 'financeView',
                component: financeView
            },
            {
                path: '/payoutAllView',
                name: 'payoutAllView',
                component: payoutAll
            },
            {
                path: '/payoutStoreView',
                name: 'payoutStoreView',
                component: payoutStore
            },
            {
                path: '/payoutTill',
                name: 'payoutTill',
                component: payoutTill
            },
            {
                path: '/safeSearch',
                name: 'safeSearch',
                component: safeSearch
            },
            {
                path: '/viewSafe/:id/:name',
                name: 'viewSafe',
                props: true,
                component: safeView
            },
            /*==============================================SALES====================================================*/
            {
                path: '/BestWorstView',
                name: 'BestWorstView',
                component: bestWorst
            },
            {
                path: '/departmentView',
                name: 'departmentView',
                component: departments
            },
            {
                path: '/saleCashierView',
                name: 'saleCashierView',
                component: saleCashier
            },
            {
                path: '/saleSummaryView',
                name: 'saleSummaryView',
                component: saleSummary
            },
            {
                path: '/kitchen-orders',
                name: 'kitchen-orders',
                component: kitchenOrders
            },
            {
                path: '/turnoverView',
                name: 'turnoverView',
                component: turnover
            },{
                path: '/invoiceSearch',
                name: 'invoiceSearch',
                component: invoiceSearch
            },{
                path: '/invoiceView/:inv',
                props: true,
                name: 'invoiceView',
                component: invoiceView
            },
            /*==============================================STOCK====================================================*/
            {
                path: '/belowMinView',
                name: 'belowMinView',
                component: belowMin
            },
            {
                path: '/stockReportView',
                name: 'stockReportView',
                component: stockreport
            },

            {
                path: '/stockTake',
                name: 'StockTakesView',
                component: stockTakesView
            },

            /*==============================================WARNING====================================================*/
            {
                path: '/warningView',
                name: 'warningView',
                component: warnings
            },
            /*==========================================================================================================*/
            {
                path: '/reports/cashUp',
                name: 'cashUpReports',
                component: cashUpReport
            },
            {
                path: '/reports/cashUp/:id/accounts',
                name: 'cashUpAcc',
                props: true,
                component: cashUpAcc
            },
            {
                path: '/reports/cashUp/:id/creditNotes',
                name: 'cashUpCred',
                props: true,
                component: cashUpCred
            },
            {
                path: '/reports/cashUp/:id/payouts',
                name: 'cashUpPay',
                props: true,
                component: cashUpPay
            },
            {
                path: '/reports/cashUp/:id/sales',
                name: 'cashUpSale',
                props: true,
                component: cashUpSale
            },
            {
                path: '/reports/cashUp/:id/moneyToSafe',
                name: 'cashUpSafe',
                props: true,
                component: cashUpSafe
            },
            {
                path: '/reports/cashUp/:id/voids',
                name: 'cashUpVoids',
                props: true,
                component: cashUpVoids
            },
            //===================================================REPORTS
            {
                path: '/reports/sales',
                name: 'salesReports',
                component: salesReport
            },
            {
                path: '/reports/stock',
                name: 'stockReports',
                component: stockReports
            },
            {
                path: '/reports/supervisor',
                name: 'supervisorReports',
                component: supervisorReports
            },
            /*==========================================HR===========================================*/
            {
                path: '/securityGroups',
                name: 'securityGroups',
                component: securityGroups
            },

            {
                path: '/selectSecurityType',
                name: 'selectSecurityType',
                component: selectSecurityType
            },

            {
                path: '/updateSecurity',
                name: 'updateSecurity',
                component: updateSecurity
            },

            {
                path: '/selectDevice',
                name: 'selectDevice',
                component: selectDevice
            },

            {
                path: '/settings',
                name: 'settings',
                component: settings
            },
        ],
    },

    {
        path: '/email-settings',
        name: "email-settings",
        component :emails ,
    },


];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

export default router
