import Swal from 'sweetalert2';
import {LocalDate} from "@js-joda/core";
import {store} from '../store/index';

export const util = {
    formatNum: (num) => {
        num = parseFloat(num.toFixed(2));
        return num;
    },
    makePositive(num) {
        if (num < 0) {
            num = num * -1;
        }
        return num;
    },
    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",",isCurrency= true) {
        try {
            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

            const negativeSign = amount < 0 ? "-" : "";

            let i = parseInt(
                (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
            ).toString();
            let j = i.length > 3 ? i.length % 3 : 0;
            let currency = isCurrency?store.getters.getBaseCurrency.Symbol:"";
            return (
                 currency+
                negativeSign +
                (j ? i.substr(0, j) + thousands : "") +
                i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
                (decimalCount
                    ? decimal +
                    Math.abs(amount - i)
                        .toFixed(decimalCount)
                        .slice(2)
                    : "")
            );
        } catch (e) {
            console.log(e);
        }
    },
    async getDateRange() {
        const {value: formValues} = await Swal.fire({
            title: 'Date Range',
            html:
                '<input class="form-control mb-3" type="date" id="from" name="From" placeholder="From">' +
                '<input class="form-control" type="date" id="to" name="To" placeholder="To">',
            focusConfirm: false,
            preConfirm: () => {
                return [
                    document.getElementById('from').value,
                    document.getElementById('to').value
                ]
            }
        });
        if (formValues) {
            //let Arr = JSON.stringify(formValues);
            return Promise.resolve(formValues);
        } else {
            return Promise.reject('Empty');
        }
    },
    getColor(amount) {
        if (amount == 0) return 'text-success bo_ld';
        else if (amount > 0) return 'text-warning bo_ld';
        else if (amount < 0) return 'text-danger bo_ld';
    },
    getInOutColor(amount) {
        if (amount == 0) return 'text-success bo_ld';
        else if (amount > 0) return 'text-success bo_ld';
        else if (amount < 0) return 'text-warning bo_ld';
    },
    showLoading(show) {
        return store.commit(`setLoading`,show)
    },

    formatDate(date) {
        let d = new Date(date);
        return d.toLocaleDateString();
    },
    getDayRange(day) {
        let from = LocalDate.now().atStartOfDay().plusHours(store.state.Period);
        if (day) {
            from = LocalDate.parse(day).atStartOfDay().plusHours(store.state.Period);
        }
        let to = from.plusDays(1);

        return {
            "from": `${from.year()}-${(from.month().value() < 10) ? "0" + from.month().value() : from.month().value()}-${(from.dayOfMonth() < 10) ? "0" + from.dayOfMonth() : from.dayOfMonth()} ${(from.hour() < 10) ? 0 + from.hour() : from.hour()}:00:00`,
            "to": `${to.year()}-${(to.month().value() < 10) ? "0" + to.month().value() : to.month().value()}-${(to.dayOfMonth() < 10) ? "0" + to.dayOfMonth() : to.dayOfMonth()} ${(to.hour() < 10) ? 0 + to.hour() : to.hour()}:00:00`,
            "update": true
        };
    },
    getYesterday() {
        const today = new Date();
        // eslint-disable-next-line no-unused-vars
        const tomorrow = new Date();
        tomorrow.setDate(today.getDate() - 1);
        // eslint-disable-next-line no-unused-vars
        return tomorrow.toISOString().substring(0, 10);
    },

    getAdvancedRange(from, to) {
        let fromDate = LocalDate.parse(from).atStartOfDay().plusHours(store.state.Period);
        let toDate = LocalDate.parse(to).atStartOfDay().plusHours(store.state.Period);
        return {
            from: `${fromDate.year()}-${(fromDate.month().value() < 10) ? "0" + fromDate.month().value() : fromDate.month().value()}-${(fromDate.dayOfMonth() < 10) ? "0" + fromDate.dayOfMonth() : fromDate.dayOfMonth()} ${(fromDate.hour() < 10) ? 0 + fromDate.hour() : fromDate.hour()}:00:00`,
            to: `${toDate.year()}-${(toDate.month().value() < 10) ? "0" + toDate.month().value() : toDate.month().value()}-${(toDate.dayOfMonth() < 10) ? "0" + toDate.dayOfMonth() : toDate.dayOfMonth()} ${(toDate.hour() < 10) ? 0 + toDate.hour() : toDate.hour()}:00:00`,
            update: true
        };
    }

};
