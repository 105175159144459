import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default {
    name: "CashUpModule",

    state: {
        CashUpList: [],
        CashUp: [],
        CashUpSales: [],
        CashUpPayout: [],
        CashUpAccPayment: [],
        CashUpCashToSafe: [],
        CashUpVoids: [],
        CashUpCreditNotes: [],

    },
    getters: {
        getCashUpList: state => {
            return state.CashUpList;
        },
        getCashUpSales: state => {
            return state.CashUpSales;
        },
        getCashUpPayout: state => {
            return state.CashUpPayout;
        },
        getCashUpAccPayment: state => {
            return state.CashUpAccPayment;
        },
        getCashUpCashToSafe: state => {
            return state.CashUpCashToSafe;
        },
        getCashUpVoids: state => {
            return state.CashUpVoids;
        },
        getCashUpCreditNotes: state => {
            return state.CashUpCreditNotes;
        },
    },
    mutations: {
        setCashUpList: (state, payload) => {
            let f = payload.f;
            if (f === true) {
                state.CashUpList = payload.d;
            } else {
                state.CashUpList = state.CashUpList.concat(payload.d);
            }
        },
        setCashUpSales: (state, payload) => {
            let f = payload.f;
            if (f === true) {
                state.CashUpSales = payload.d;
            } else {
                state.CashUpSales = state.CashUpSales.concat(payload.d);
            }
        },
        setCashUpPayout: (state, payload) => {
            let f = payload.f;
            if (f === true) {
                state.CashUpPayout = payload.d;
            } else {
                state.CashUpPayout = state.CashUpPayout.concat(payload.d);
            }
        },
        setCashUpAccPayment: (state, payload) => {
            let f = payload.f;
            if (f === true) {
                state.CashUpAccPayment = payload.d;
            } else {
                state.CashUpAccPayment = state.CashUpAccPayment.concat(payload.d);
            }
        },
        setCashUpCashToSafe: (state, payload) => {
            let f = payload.f;
            if (f === true) {
                state.CashUpCashToSafe = payload.d;
            } else {
                state.CashUpCashToSafe = state.CashUpCashToSafe.concat(payload.d);
            }
        },
        setCashUpVoids: (state, payload) => {
            let f = payload.f;
            if (f === true) {
                state.CashUpVoids = payload.d;
            } else {
                state.CashUpVoids = state.CashUpVoids.concat(payload.d);
            }
        },
        setCashUpCreditNotes: (state, payload) => {
            let f = payload.f;
            if (f === true) {
                state.CashUpCreditNotes = payload.d;
            } else {
                state.CashUpCreditNotes = state.CashUpCreditNotes.concat(payload.d);
            }
        },
    },
    actions: {
        searchCashUp: ({state, rootState, commit}, data) => {
            return new Promise((resolve, reject) => {
                return axios.get(rootState.IP + `/cashup/getCashUpAll/${rootState.CurStore.StoreDB}`, {
                    params: data
                })
                    .then(res => {
                        let payload = {'f': data.first, 'd': res.data};
                        commit("setCashUpList", payload)
                    })
                    .catch((err) => {
                        console.log(err);
                        reject(err);
                    })
            })
        },
        getCashUpDetails: ({state, rootState, dispatch}, data) => {
            return new Promise((resolve, reject) => {
                return axios.get(rootState.IP + `/cashup/getCashUpDetails/${rootState.CurStore.StoreDB}`, {
                    params: data
                }).then(res => {
                    let payload = {'f': data.first, 'd': res.data, 'type': data.type};
                    dispatch("setCashUpDetails", payload);
                    resolve('success')
                }).catch(err => {
                    reject(err);
                })
            })
        }, setCashUpDetails: ({state, commit}, payload) => {
            let type = payload.type;
            switch (type) {
                case 'sales':
                    commit("setCashUpSales", payload);
                    break;
                case 'voids':
                    commit("setCashUpVoids", payload);
                    break;
                case 'Acc':
                    commit("setCashUpAccPayment", payload);
                    break;
                case 'cashSafe':
                    commit("setCashUpCashToSafe", payload);
                    break;
                case 'payOuts':
                    commit("setCashUpPayout", payload);
                    break;
                case 'credit':
                    commit("setCashUpCreditNotes", payload);
                    break;
            }
        }
    }

}