import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default {
    name: 'Warning',
    state: {
        ActiveWarnings: [],
        warningCount: 0,
    },
    getters: {
        getWarnings: (state) => {
            return state.ActiveWarnings;
        },
        getWarningsCount: (state) => {
            return state.warningCount;
        }
    },
    mutations: {
        setWarnCount: (state, data) => {
            state.warningCount = data;
        },
        setActiveWarnings: (state, data) => {
            state.ActiveWarnings = data;
        }
    },
    actions: {
        ActiveWarning: ({commit, rootState}) => {
            return axios
                .get(
                    rootState.IP + "/warning/getActiveWarning/" + rootState.CurStore.StoreDB, {
                        params: rootState.Range,
                    }
                )
                .then((res) => {
                    commit("setWarnCount", res.data.count);
                    commit("setActiveWarnings", res.data.warning);
                })
                .catch((err) => console.log(err));
        }
    }
};
