import Vue from 'vue';
import App from './App.vue';
import router from './router';
import {store} from './store';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2';
import {util} from './Utils/Utils.js';
import {VueHammer} from 'vue2-hammer';
import VueSweetalert2 from 'vue-sweetalert2';
import qs_stringify from 'qs-stringify';
import {errorHandler} from "@/Utils/errorHandler";
import TimeManager from "@/Utils/TimeManager";
import queryStringify from "qs-stringify";




Vue.use(VueHammer);
Vue.use(VueSweetalert2);
window.errorHandler = errorHandler

window.franchiseTomcatApi = `https://overflow.abc.co.zm:8443/Franchise_OnlineServer/`
// window.franchiseTomcatApi = `http://192.168.1.133:8080/Franchise_OnlineServer/`

window.backOfficeTomcatApi = `https://tom9.abc.co.zm/Lyt-SoftBackOfficeApi/`

window.stringify = (object) => queryStringify(object);
window.openUrl = (url,target = '_blank') => window.open(url,target);

Vue.prototype.$util = util;
window.dateTimeManager = TimeManager;

window.Stringify = qs_stringify;
Vue.prototype.$toast = function (icon, text,position = 'top-center',timer = 3000) {
    const Toast = Swal.mixin({
        toast: true,
        position: position,
        showConfirmButton: false,
        timer: timer,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
    });
    Toast.fire({
        icon: icon,
        title: text
    })

};
Vue.prototype.$getDateRange = async function () {
    const {value: formValues} = await Swal.fire({
        title: 'Date Range',
        html:
            '<input class="form-control mb-3" type="date" id="from" name="From" placeholder="From">' +
            '<input class="form-control" type="date" id="to" name="To" placeholder="To">',
        focusConfirm: false,
        position: 'bottom',
        showClass: {
            popup: `animate__animated animate__fadeInUp animate__faster`
        },
        hideClass: {
            popup: `animate__animated animate__fadeOutDown animate__faster`
        },
        grow: 'row',
        showCancelButton: true,
        backdrop: true,
        preConfirm: () => {
            return [
                document.getElementById('from').value,
                document.getElementById('to').value
            ]
        }
    });
    //if (formValues) {
    return formValues;
    //}

};
Vue.prototype.$getDate = async function () {
    const {value: formValues} = await Swal.fire({
        title: 'Select Date',
        html:
            '<input class="form-control mb-3" type="date" id="day" name="From" placeholder="Day">',
        focusConfirm: false,
        position: 'bottom',
        showClass: {
            popup: `animate__animated animate__fadeInUp animate__faster`
        },
        hideClass: {
            popup: `animate__animated animate__fadeOutDown animate__faster`
        },
        grow: 'row',
        showCancelButton: true,
        backdrop: true,
        preConfirm: () => {
            return [
                document.getElementById('day').value
            ]
        }
    });
    if (formValues) {
        console.log(formValues)
        return formValues;
    }

};
Vue.prototype.$alert = function (icon, title, text) {
    return Swal.fire({
        icon: icon,
        titleText: title,
        text: text,
        showConfirmButton: false,
        timer: 3000
    });
};

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
