import {LocalDate as LocalDate} from "@js-joda/core";
LocalDate.LocalDate;

let addIntForDate = (num) => num >= 10?num:'0'+num;

let tomorrow = LocalDate.now().plusDays(1);
tomorrow = tomorrow._year+'-'+addIntForDate(tomorrow._month)+'-'+addIntForDate(tomorrow._day);
class TimeRangeManager {

    getToday(){
        let today = LocalDate.now();
        today = today._year+'-'+addIntForDate(today._month)+'-'+addIntForDate(today._day);
        return { from : today,to : tomorrow}
    }

    getLastWeek(){
        let startOfWeek = LocalDate.now().minusDays(7);
        startOfWeek = startOfWeek._year+'-'+addIntForDate(startOfWeek._month)+'-'+addIntForDate(startOfWeek._day)
        return { from : startOfWeek,to : tomorrow}
    }


    getLastMonth(){
        let startOfMonth = LocalDate.now().minusMonths(1);
        startOfMonth = startOfMonth._year+'-'+addIntForDate(startOfMonth._month)+'-'+addIntForDate(startOfMonth._day)
        return { from : startOfMonth,to : tomorrow}
    }

    getThisMonth(){
        let now = LocalDate.now();
        let firstDayOfMonth = now._year + '-' + addIntForDate(now._month) + '-01';
        return { from : firstDayOfMonth,to : tomorrow}
    }

    getThisYear(){
        let startOfYear = LocalDate.now()._year+'-01-01'
        return { from : startOfYear,to : tomorrow}
    }


}
export default TimeRangeManager = new TimeRangeManager();
