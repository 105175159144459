import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default {
    name: 'settings',
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        updateVATStatus: async (context , payload) =>{
            let DB = context.getters.getCurStore.StoreDB;
            return axios.get(franchiseTomcatApi+`settings/${DB}/updateVATStatus`,{
                params : {pc : payload}
            }).then(({data}) => data).catch(err=>errorHandler.tomcatError(err))
        }
    }
};
