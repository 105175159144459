import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "@/router";

Vue.use(Vuex);

export default {
    name: 'Securities',
    state: {
        securityGroups : [],
    },
    getters: {
        getSecurityGroups: (state) => state.securityGroups
    },
    mutations: {
        setSecurityGroups: (state, payload) => state.securityGroups = payload
    },
    actions: {
        getAllSecurityGroups: (context) => {
            let DB = context.getters.getCurStore.StoreDB;
            let ApiServer = context.rootState.IP;
            return axios.get(ApiServer+`/EmpSecurity/getAllSecurityGroups/${DB}`)
                .then(({data})=>context.commit('setSecurityGroups',data))
        },

        getSecuritiesByType(context,payload){
            let DB = context.getters.getCurStore.StoreDB;
            let ApiServer = context.rootState.IP;
            return axios.get(ApiServer+`/EmpSecurity/getByType/${DB}`,{
                params : payload
            }).then(({data})=>data)
        },

        updateSecurity(context,payload){
            let DB = context.getters.getCurStore.StoreDB;
            let ApiServer = context.rootState.IP;
            return axios.put(ApiServer+`/EmpSecurity/${DB}`,payload)
                .then(()=>router.push({ name : 'securityGroups' }))
                .catch(err=> new Error(errorHandler.laravelError(err)))
        },


    }
};
