import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default {
    name: "Stock",
    state: {
        BellowMin: [],
        stockItem: {},
        Search: [],
        Departments: [],
        Stock: {},
        stockTakes : [],
        stockValue: 0,
    },
    getters: {
        getBellowMin: (state) => {
            return state.BellowMin;
        },
        getStockItem: (state) => {
            return state.Stock;
        },
        getStockTrans: (state) => {
            return state.Trans;
        },
        getSearch: (state) => {
            return state.Search;
        },
        getMainDepartments: (state) => {
            const data = state.Departments;
            return [...new Set(data.map(item => item.MainCatName))];
        },
        getSubDep: (state) => (dep) => {
            let data = state.Departments;
            return data.filter(value => {
                let obj = value;
                if (obj.MainCatName === dep) {
                    return obj.SubCatName;
                }
            });
        },
        getSub2: (state) => (subDep) => {
            let data = state.Departments;
            return data.filter(value => {
                let obj = value;
                if (obj.SubCatName === subDep) {
                    return obj.SubCatName2;
                }
            });
        },
        getStockValue: (state) => state.stockValue,
        getStockTakes: (state) => state.stockTakes,
    },
    mutations: {
        setBellowMin: (state, data) => state.BellowMin = data,
        setStockItem: (state, data) => state.Stock = data,
        setSearch: (state, payload) => {
            if (payload.f === true) {
                state.Search = payload.d;
            } else {
                state.Search = state.Search.concat(payload.d);
            }
        },
        setDepartments: (state, data) => state.Departments = data,
        setStockTakes : (state , data) => state.stockTakes = data,
        setStockValue: (state, data) => state.stockValue = data,
    },
    actions: {
        getBellowMin: ({commit, rootState}) => {
            return axios.get(franchiseTomcatApi + `stock/${rootState.CurStore.StoreDB}/getBelowMin`)
                .then(({data}) => commit("setBellowMin", data))
                .catch((err) => console.log(err));
        },
        getStockItem: ({commit, rootState}, code) => {
            return axios.get(rootState.IP + `/stock/getStockItem/${code}/` + rootState.CurStore.StoreDB)
                .then(({data}) => commit("setStockItem", data))
                .catch((err) => console.log(err));
        },
        getStockTransfer: ({commit, rootState}) => {
            return axios.get(rootState.IP + "/stock/getStockTransfer/" + rootState.CurStore.StoreDB,
                {params: rootState.Range})
                .then(({data}) => commit("setStockTransfer", data))
                .catch((err) => console.log(err));
        },
        SearchStock: ({commit, rootState}, payload) => {
            return axios.get(rootState.IP + "/stock/searchstock/" + rootState.CurStore.StoreDB, {params: payload})
                .then(({data}) => {
                    const res = {'d': data, 'f': payload.first};
                    commit("setSearch", res);
                })
                .catch((err) => console.error(err));
        },
        getDepartments: ({commit, rootState}) => {
            return axios.get(rootState.IP + "/Category/getAll/" + rootState.CurStore.StoreDB)
                .then(({data}) => commit("setDepartments", data));
        },
        getStockValue: ({rootState, commit}) => {
            return axios.get(rootState.IP + `/stockvalue/getCurrent/${rootState.CurStore.StoreDB}`)
                .then(({data}) => commit('setStockValue', data))
                .catch((err) => console.log(err));
        },
        getAllStockTakes:(context) => {
            let store = context.getters.getCurStore;
            return axios.get(franchiseTomcatApi+`stockTake/${store.StoreDB}/getAll`,{
                params : context.getters.getCurrentRange,
            }).then(({data})=>context.commit(`setStockTakes`,data))
        }

    },
};
