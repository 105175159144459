<template>
  <div class="page">
    <div class="row cover-header">
      <img alt="" class="img-fluid" src="../assets/images/logo.png"/>
    </div>
    <form @submit.prevent="login" class="login-box">

      <div class="col-12 text-center text-secondary">
        <h6>LOGIN</h6>
      </div>
      <div class="form-group">
        <label>Username</label>
        <i class="far fa-user icon"></i>
        <input class="form-control" placeholder="Username" v-model="email" type="text" autocomplete="email" required/>
      </div>
      <div class="form-group">
        <label>Password</label>
        <i class="fas fa-lock icon"></i>
        <input class="form-control" placeholder="Password"
               v-model="password" type="password" autocomplete="current-password" required/>
      </div>


      <div class="form-check mb-3">
        <label class="form-check-label" for="remember">
          <input class="form-check-input" id="remember"
                 type="checkbox" v-model="remember"/>Remember Me</label>
      </div>

      <router-link :to="{ name : 'OTPLogin' }" class="float-right mb-2">Use OTP</router-link>

      <button type="submit" class="login_btn">
        Login
      </button>
    </form>
  </div>
</template>

<script>
import TimeManager from "@/Utils/TimeManager";

export default {
  name: "loginScreen",
  data() {
    return {
      remember: false,
      email: '',
      password: '',
    };
  },
  methods: {
    login() {
      this.$util.showLoading(true);
      return this.$store.dispatch("Login", {
        email: this.email,
        password: this.password,
      }).then(() => {
        if (this.remember === true) {
          this.$store.commit("SET_REMEMBER", {
            Username: this.email,
            Password: this.password,
          });
        }
        return this.$router.push({name: "selectStore"});
      }).catch((reject) => this.$alert("error", "User Not Found!", "incorrect Username or Password"))
          .finally(() => this.$util.showLoading(false));
    },

    async getObject() {
      let autoLog = this.$route.query.autoLog;

      if (autoLog) {
        let jsonData = window.atob(autoLog.toString());
        localStorage.setItem(`remember`, jsonData);
        this.$store.commit(`setAutoLogin`)
      }
      const ret = localStorage.getItem('remember')
      const user = JSON.parse(ret);
      if (user) {
        return this.runLogin({
          email: user.username,
          password: user.password,
          loginToStore: !!autoLog
        })
      }

    },

    runLogin(data) {
      this.$util.showLoading(true);
      return this.$store.dispatch("Login", data).then((res) => {
            if (res === true) return this.$router.push({name: "selectStore"})
          }).finally(() => this.$util.showLoading(false));
    },

    getNow() {
      let data = TimeManager.getToday();
      this.$store.commit("Start", data);
      this.$store.commit("DayStart", data);
      this.$store.state.TempDateChanges.original = data;
    },
  },
  beforeMount() {
    this.getObject();
    this.getNow();
    this.$store.commit("StartMonth", TimeManager.getThisMonth());
  },
};
</script>
<style>
body.swal2-shown > [aria-hidden="true"] {
  filter: blur(10px);
}

body > * {
  transition: 0.1s filter linear;
}
</style>

<style lang="scss" scoped>
.page {
  position: relative;
  background: linear-gradient(0deg, #15356E 0%, #3F8DCD 100%);
  background-size: cover;
  height: 100vh;
  top: 0;
  width: 100vw;
  min-height: 100vh;
  transition: 0.3s all ease-in-out;
}

.img-fluid {
  height: 50%;
  transition: 2s all ease-in-out;
  transform-origin: center;

  &
  :hover {
    transform: rotate(720deg);
  }

}

.cover-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
  height: 50%;
  border-radius: 0 0 15em 15em;
  /*background-image: url("../assets/images/bg.png");*/
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-height: 750px) {
  .cover-header {
    height: 46%;
  }
}

.login-box {
  position: absolute;
  bottom: 23%;
  right: 50%;
  transform: translate(50%, 38%);
  width: 90vw;
  background: white;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 1.5em;
  box-shadow: 0 4px 7px rgba(0, 0, 0, 0.5);

  .form-group {
    position: relative;

    .icon {
      position: absolute;
      left: 10px;
      border-right: 1px solid rgba(222, 222, 222, 0.5);
      padding-right: 5px;
      top: 37px;
      color: rgba(200, 200, 200, 0.9);
      font-size: 1.6em;
    }

  }

  .form-control {
    border: 1px solid #ccc;
    background: rgba(0, 0, 0, 0);
    display: block;
    margin: 5px auto;
    text-align: left;
    padding: 0 40px 0 50px;
    height: 40px;
    border-radius: 1em;
    outline: none;
    color: #a0a0a0;
    transition: ease-in-out 200ms;
  }

  .form-control:focus {
    background-color: transparent;
    font-weight: 400;
    outline: none;
  }

  .login_btn {
    width: 100%;
    height: 40px;
    font-size: 0.8em;
    color: white;
    padding-left: 20px;
    text-transform: uppercase;
    font-weight: 300;
    background: linear-gradient(205deg, #0B2550 0%, #295687 100%);
    border-radius: 1em;
    outline: none;
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  }

  label {
    font-style: italic;
    font-weight: 500;
    font-size: 1em;
    color: #6c757d !important;
    margin-bottom: 0;
    margin-left: 20px;
  }

}
</style>
