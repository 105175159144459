import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default {
    name: "Accounts",

    state: {
        Discounts: 0,
        accPayed: 0,
        Owed: 0,
        BFDate: "",
        DebDiscount: 0,
        DebOwed: 0,
        DebPayed: 0,
        AllDebTrans: [],
        DebtorInvoices: [],
        DebtorCreditNotes: [],
        DebtorPayments: [],
        Debtors: [],
        Debtor: {},
        allBalances: 0.00
    },
    getters: {
        getDebtors: store => {
            return store.Debtors
        },
        getDebtor: state => {
            return state.Debtor;
        },
        getDebtorInvoices: (state) => {
            return state.DebtorInvoices
        },
        getDebtorCreditNotes: (state) => {
            return state.DebtorCreditNotes
        },
        getDebtorPayments: (state) => {
            return state.DebtorPayments
        },
        getPayedAcc: state => {
            return state.accPayed
        },
        getDiscounts: state => {
            return state.Discounts
        },
        getOwed: state => {
            return state.Owed
        },
        getBroughtForward: state => {
            return state.BFDate;
        },
        getAllBalance: (state) => state.allBalances,
    },
    mutations: {
        setSearchDebtors: (state, payload) => {
            let first = payload.f;
            let data = payload.d;
            if (first === true) {
                state.Debtors = data
            } else {
                state.Debtors = [state.Debtors, ...data];
            }
        },
        setDebtor: (state, payload) => {
            state.Debtor = payload;
        },
        setDebtorInvoices: (state, payload) => {
            let f = payload.f;
            if (f === true) {
                state.DebtorInvoices = payload.d;
            } else {
                state.DebtorInvoices = state.DebtorInvoices.concat(payload.d);
            }
        },
        setDebtorCreditNotes: (state, payload) => {
            let f = payload.f;
            if (f === true) {
                state.DebtorCreditNotes = payload.d;
            } else {
                state.DebtorCreditNotes = state.DebtorCreditNotes.concat(payload.d);
            }
        },
        setDebtorPayments: (state, payload) => {
            let f = payload.f;
            if (f === true) {
                state.DebtorPayments = payload.d;
            } else {
                state.DebtorPayments = state.DebtorPayments.concat(payload.d);
            }
        },
        setAllPayed: (state, payload) => {
            state.DebPayed = payload.amount;
        },
        setAllDiscounts: (state, payload) => {
            state.DebDiscount = payload.amount;
        },
        setAll_Owed: (state, payload) => {
            state.Owed = payload.amount;
        },
        setBFDate: (state, payload) => {
            state.BFDate = payload;
        },
        setAllBalances: (state, data) => state.allBalances = data,
    },
    actions: {
        getAllDiscoutAcc: ({state, rootState}) => {
            return axios.get(rootState.IP + `/account/getAllDiscoutAcc/` + rootState.CurStore.StoreDB, {params: {BF: state.BFDate},})
                .then(({data}) => state.Discounts = data.amount)
        },
        getAllDebtor: ({state, rootState}) => {
            return axios.get(rootState.IP + "/account/getAllDebtor/" + rootState.CurStore.StoreDB, {params: rootState.Range,})
                .then(({data}) => state.Debtors = data)
                .catch((err) => console.log(err));
        },
        getBFDate: ({state, rootState, commit}, id) => {
            return axios.get(rootState.IP + `/account/getBFDate/${id}/` + rootState.CurStore.StoreDB)
                .then(({data}) => commit("setBFDate", data))
                .catch((err) => console.log(err));
        },
        getAllPayed: ({state, rootState}, debtor) => {
            return axios
                .get(rootState.IP + "/account/getAllPayed/" + rootState.CurStore.StoreDB, {
                    params: debtor
                })
                .then((res) => {
                    return res.data;
                })
                .catch((err) => console.log(err));
        },
        getAllOwed: ({state, rootState}, debtor) => {
            return axios
                .get(rootState.IP + "/account/getAllOwed/" + rootState.CurStore.StoreDB, {
                    params: debtor
                })
                .then((res) => {
                    return res.data;
                })
                .catch((err) => console.log(err));
        },
        getBalance: ({state, rootState}, debtor) => {
            return axios.get(rootState.IP + "/account/getAllOwed/" + rootState.CurStore.StoreDB, {params: debtor})
                .then(({data}) => data)
                .catch((err) => console.log(err));
        },
        getAllTransAction: ({state, rootState}, debtor) => {
            return axios.get(rootState.IP + `/account/getAllTransAction/${debtor}/` + rootState.CurStore.StoreDB, {params: rootState.Range,})
                .then(({data}) => state.DebTrans = data)
                .catch((err) => console.log(err));
        },
        searchDebtor: ({rootState, commit}, debtor) => {
            return axios.get(rootState.IP + `/account/searchDebtor/` + rootState.CurStore.StoreDB, {
                params: {
                    Name: debtor.Name,
                    limit: debtor.limit,
                    offset: debtor.offset
                }
            }).then(({data}) => {
                const payload = {'d': data, 'f': debtor.first};
                commit("setSearchDebtors", payload);
            }).catch((err) => console.log(err));
        },
        getDebtor: ({state, rootState, commit, dispatch}, debtor) => {
            let storeDB = rootState.CurStore.StoreDB;
            return axios.get(rootState.IP + `/account/getDebtor/${debtor}/` + storeDB,)
                .then(({data}) => commit("setDebtor", data))
                .catch((err) => console.log(err));
        },
        getDebtorInv: ({state, rootState, commit}, deb) => {
            let storeDB = rootState.CurStore.StoreDB;
            return axios.get(rootState.IP + `/account/getDebInvoices/${deb.id}/` + storeDB, {params: deb})
                .then(({data}) => {
                    let payload = {'d': data, 'f': deb.first};
                    commit("setDebtorInvoices", payload);
                }).catch((err) => console.log(err));
        },
        getDebtorCredit: ({state, rootState, commit}, deb) => {
            let storeDB = rootState.CurStore.StoreDB;
            return axios.get(rootState.IP + `/account/getDebCreditNotes/${deb.id}/` + storeDB, {params: deb})
                .then(({data}) => {
                    let payload = {'d': data, 'f': deb.first};
                    commit("setDebtorCreditNotes", payload);
                }).catch((err) => console.log(err));
        },
        getDebtorPayment: ({state, rootState, commit}, deb) => {
            return axios.get(rootState.IP + "/AccPayments/getDebtorId/" + deb.id + "/" + rootState.CurStore.StoreDB,
                {params: deb})
                .then(({data}) => {
                    let payload = {'d': data, 'f': deb.first};
                    commit("setDebtorPayments", payload);
                }).catch((err) => console.log(err));
        },
        getAllDebtorBalance: ({commit, rootState}) => {
            return axios.get(rootState.IP + `/account/getAllBalance/` + rootState.CurStore.StoreDB)
                .then(({data}) => commit('setAllBalances', data))
                .catch(err => console.log(err));
        },
        printDebtorStatement: async ({rootState}, id) => {
            return openUrl(backOfficeTomcatApi + `debtor/${rootState.CurStore.StoreDB}/statement?` + stringify({
                id : id,
                user : 'Mobile'
            }));
        },
    },
};
