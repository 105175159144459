import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default {
    name: 'Print',
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        printBusinessOverview : (context, range) => {
            let url  = context.rootState.IP+`/PDFReport/StoreOverview/${context.rootState.CurStore.StoreDB}?`+Stringify(range);
            window.open(url,'_blank');
        },

        printDailyOverview : (context, range) => {
            let url  = context.rootState.IP+`/PDFReport/Daily/${context.rootState.CurStore.StoreDB}?`+Stringify(range);
            window.open(url,'_blank');
        },

        printStockTakesSummary : (context, range) => {
            let url  = context.rootState.IP+`/PDFReport/printAllStockTake/${context.rootState.CurStore.StoreDB}?user=All&limit=500&`+Stringify(range);
            window.open(url,'_blank');
        },

        printSalesSummary : (context, range) => {
            let url  = context.rootState.IP+`/PDFReport/SalesSummary/${context.rootState.CurStore.StoreDB}?`+Stringify(range);
            window.open(url,'_blank');
        },

        printCashUpSummary : (context, range) => {
            let url  = context.rootState.IP+`/PDFReport/AllCashUp/${context.rootState.CurStore.StoreDB}?`+Stringify(range);
            window.open(url,'_blank');
        },

        printStockOverview : (context, range) => {
            let url  = context.rootState.IP+`/PDFReport/getStockOverview/${context.rootState.CurStore.StoreDB}?`+Stringify(range);
            window.open(url,'_blank');
        },
    }
};
