import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default {
    name: "Invoices",
    state: {
        Search: [],
        invoiceItems: []
    },
    getters: {
        getInvoiceSearch: (state) => {
            return state.Search;
        },
        getInvoiceItems: (state) => {
            return state.invoiceItems;
        },

    },
    mutations: {
        setInvoiceSearch: (state, payload) => {
            if (payload.f === true) {
                state.Search = payload.data;
            } else {
                state.Search = state.Search.concat(payload.data);
            }

        },
    },
    actions: {
        searchInvoices: ({state, rootState, commit}, data) => {
            return axios.get(rootState.IP + `/CompletedSale/searchInvoices/${rootState.CurStore.StoreDB}`, {
                params: data,
            }).then((res) => {
                let payload = {"data": res.data, "f": data.first};
                commit("setInvoiceSearch", payload);
            })
        },
        getInvoiceItems: ({rootState}, invoice) => {
            return axios.get(franchiseTomcatApi + `completedsale/${rootState.CurStore.StoreDB}/getInvNum`, {
                params: {invoice: invoice}
            }).then(({data}) => data)
        }
    },
};
