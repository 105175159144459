import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default {
    name: 'Audit',
    state: {
        voidItem: {},
        Voids: [],
        RefundItem: {},
        Refunds: [],
        DiscountItem: {},
        Discounts: [],
    },
    getters: {
        getVNum: (state) => {
            return state
        },
        getVCashUp: (state) => {
            return state
        },
        getVDate: (state) => {
            return state.Voids
        },
        getRefCashUp: (state) => {
            return state
        },
        getRefNum: (state) => {
            return state
        },
        getRefDate: (state) => {
            return state.Refunds
        },
        getDisNum: (state) => {
            return state
        },
        getDisDate: (state) => {
            return state.Discounts;
        },
    },
    mutations: {
        setVNum: (state, data) => {
            state.void = data
        },
        setVCashUp: (state, data) => {
            state.void = data
        },
        setVDate: (state, data) => {
            state.Voids = data
        },
        setRefCashUp: (state, data) => {
            state.void = data
        },
        setRefNum: (state, data) => {
            state.void = data
        },
        setRefDate: (state, data) => {
            state.Refunds = data
        },
        setDisNum: (state, data) => {
            state.void = data
        },
        setDisDate: (state, data) => {
            state.Discounts = data
        },
    },
    actions: {
        getVoidNum: ({commit, rootState}, num) => {
            return axios
                .get(
                    rootState.IP + "/audit/getVoidNum/" + num + "/" + rootState.CurStore.StoreDB, {
                        params: rootState.Range,
                    }
                )
                .then((res) => {
                    commit("setVNum", res.data);
                })
                .catch((err) => console.log(err));
        },
        getVoidCashup: ({commit, rootState}, num) => {
            return axios
                .get(
                    rootState.IP + "/audit/getVoidCashup/" + num + "/" + rootState.CurStore.StoreDB, {
                        params: rootState.Range,
                    }
                )
                .then((res) => {
                    commit("setVCashUp", res.data);
                })
                .catch((err) => console.log(err));
        },
        getVoidDate: ({commit, rootState}, date) => {
            return axios
                .get(
                    rootState.IP + "/audit/getVoidDate/" + rootState.CurStore.StoreDB, {
                        params: date,
                    }
                )
                .then((res) => {
                    commit("setVDate", res.data);
                })
                .catch((err) => console.log(err));
        },
        getRefundCashUp: ({commit, rootState}, num) => {
            return axios
                .get(
                    rootState.IP + "/audit/getRefundCashup/" + num + "/" + rootState.CurStore.StoreDB, {
                        params: rootState.Range,
                    }
                )
                .then((res) => {
                    commit("setRefCashUp", res.data);
                })
                .catch((err) => console.log(err));
        },
        getRefundNum: ({commit, rootState}, num) => {
            return axios
                .get(
                    rootState.IP + "/audit/getRefundNum/" + num + "/" + rootState.CurStore.StoreDB, {
                        params: rootState.Range,
                    }
                )
                .then((res) => {
                    commit("setRefNum", res.data);
                })
                .catch((err) => console.log(err));
        },
        getRefundDate: ({commit, rootState}, date) => {
            return axios
                .get(
                    rootState.IP + "/audit/getRefundDate/" + rootState.CurStore.StoreDB, {
                        params: date,
                    }
                )
                .then((res) => {
                    commit("setRefDate", res.data);
                })
                .catch((err) => console.log(err));
        },
        getDiscountNum: ({commit, rootState}, num) => {
            return axios
                .get(
                    rootState.IP + "/audit/getDiscountNum/" + num + "/" + rootState.CurStore.StoreDB, {
                        params: rootState.Range,
                    }
                )
                .then((res) => {
                    commit("setDisNum", res.data);
                })
                .catch((err) => console.log(err));
        },
        getDiscountDate: ({commit, rootState}, date) => {
            return axios
                .get(
                    rootState.IP + "/audit/getDiscountDate/" + rootState.CurStore.StoreDB, {
                        params: date,
                    }
                )
                .then((res) => {
                    commit("setDisDate", res.data);
                })
                .catch((err) => console.log(err));
        },

    }
};
