import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default {
    name: "Sales",
    state: {
        TopUser: [],
        CashCardTurn: [],
        TopPc: [],
        TopSellerQty: [],
        TopSellerVal: [],
        WorstSellerQty: [],
        WorstSellerVal: [],
        MonthSales: [],
        YearlySales: [],
        NotCashedUp: [],
        AvarageSaleVal: [],
        TopDepartment: [],
        TopSubDepartment: [],
        DashCashCardTurn: [],
        HourSales: [],
        openTablesTotal: 0.00,
    },
    getters: {
        getTopUser: (state) => state.TopUser,
        getCashCardTurn: (state) => state.CashCardTurn,
        getTopPC: (state) => state.TopPc,
        getTopSellerQty: (state) => state.TopSellerQty,
        bestByVal: (state) => state.TopSellerVal,
        worstByQty: (state) => state.WorstSellerQty,
        worstByVal: (state) => state.WorstSellerVal,
        getMonthSell: (state) => state.MonthSales,
        getTopDep: (state) => state.TopDepartment,
        getTopSubDep: (state) => state.TopSubDepartment,
        getDCashCard: (state) => state.DashCashCardTurn,
        getHourlySales: (state) => state.HourSales,
        getOpenTableTotal: (state) => state.openTablesTotal
    },
    mutations: {
        setTopUser: (state, data) => state.TopUser = data,
        setTopPc: (state, data) => state.TopPc = data,
        setTopSellQty: (state, data) => state.TopSellerQty = data,
        setTopSellVal: (state, data) => state.TopSellerVal = data,
        setWorstSellQty: (state, data) => state.WorstSellerQty = data,
        setWorstSellVal: (state, data) => state.WorstSellerVal = data,
        setMonthlySells: (state, data) => state.MonthSales = data,
        setYearlySells: (state, data) => state.YearlySales = data,
        setNotCashedUp: (state, data) => state.NotCashedUp = data,
        setTopDep: (state, data) => state.TopDepartment = data,
        setTopSubDep: (state, data) => state.TopSubDepartment = data,
        setAvgSellVal: (state, data) => state.AvarageSaleVal = data,
        setCashCardTurn: (state, data) => state.CashCardTurn = data,
        setDashCashCardTurn: (state, data) => state.DashCashCardTurn = data,
        setSalePerHour: (state, data) => state.HourSales = data,
        setOpenTablesTotal: (state, data) => state.openTablesTotal = data,
    },
    actions: {
        getTopUser: ({commit, rootState}) => {
            return axios.get(rootState.IP + "/sales/getTopUser/" + rootState.CurStore.StoreDB, {params: rootState.Range})
                .then(({data}) => commit("setTopUser", data))
                .catch((err) => console.log(err));
        },

        getTopPc: ({commit, rootState}) => {
            return axios.get(rootState.IP + "/sales/getTopPc/" + rootState.CurStore.StoreDB, {params: rootState.Range})
                .then(({data}) => commit("setTopPc", data))
                .catch((err) => console.log(err));
        },

        getTopSellerQty: ({commit, rootState},todayOnly = false) => {
            let dateRange = rootState.Range;
            if (todayOnly) dateRange = dateTimeManager.getToday();
            return axios.get(rootState.IP + "/sales/getTopSellerQty/" + rootState.CurStore.StoreDB, {params:dateRange})
                .then(({data}) => commit("setTopSellQty", data))
                .catch((err) => console.log(err));
        },

        getTopSellerVal: ({commit, rootState},todayOnly = false) => {
            let dateRange = rootState.Range;
            if (todayOnly) dateRange = dateTimeManager.getToday();
            return axios.get(rootState.IP + "/sales/getTopSellerVal/" + rootState.CurStore.StoreDB, {params:dateRange})
                .then(({data}) => commit("setTopSellVal", data))
                .catch((err) => console.log(err));
        },

        getWorstSellerQty: ({commit, rootState},todayOnly = false) => {
            let dateRange = rootState.Range;
            if (todayOnly) dateRange = dateTimeManager.getToday();
            return axios.get(rootState.IP + "/sales/getWorstSellerQty/" + rootState.CurStore.StoreDB, {params:dateRange})
                .then(({data}) => commit("setWorstSellQty", data))
                .catch((err) => console.log(err));
        },

        getWorstSellerVal: ({commit, rootState},todayOnly = false) => {
            let dateRange = rootState.Range;
            if (todayOnly) dateRange = dateTimeManager.getToday();
            return axios.get(rootState.IP + "/sales/getWorstSellerVal/" + rootState.CurStore.StoreDB, {params:dateRange})
                .then(({data}) => commit("setWorstSellVal", data))
                .catch((err) => console.log(err));
        },

        getMonthSales: ({commit, rootState}) => {
            return axios.get(rootState.IP + "/sales/getMonthSales/" + rootState.CurStore.StoreDB,
                {params: dateTimeManager.getThisMonth()})
                .then(({data}) => commit("setMonthlySells", data))
                .catch((err) => console.log(err));
        },

        getYearlySales: ({commit, rootState}) => {
            return axios.get(rootState.IP + "/sales/getYearlySales/" + rootState.CurStore.StoreDB, {params: rootState.Range})
                .then(({data}) => commit("setYearlySells", data))
                .catch((err) => console.log(err));
        },

        getNotCashedUp: ({commit, rootState}) => {
            return axios.get(rootState.IP + "/sales/getNotCashedUp/" + rootState.CurStore.StoreDB, {params: rootState.Range})
                .then(({data}) => commit("setNotCashedUp", data))
                .catch((err) => console.log(err));
        },

        getTopDepartment: ({commit, rootState}) => {
            return axios.get(rootState.IP + "/sales/getTopDepartment/" + rootState.CurStore.StoreDB, {params: rootState.Range})
                .then(({data}) => commit("setTopDep", data))
                .catch((err) => console.log(err));
        },

        getTopSubDepartment: ({commit, rootState}) => {
            return axios.get(rootState.IP + "/sales/getTopSubDepartment/" + rootState.CurStore.StoreDB, {params: rootState.Range})
                .then(({data}) => commit("setTopSubDep", data))
                .catch((err) => console.log(err));
        },

        getAvarageSaleVal: ({commit, rootState}) => {
            return axios.get(rootState.IP + "/sales/getAvarageSaleVal/" + rootState.CurStore.StoreDB, {params: rootState.Range})
                .then(({data}) => commit("setAvgSellVal", data))
                .catch((err) => console.log(err));
        },

        getCashCardTurn: ({commit, rootState},todayOnly = false) => {
            let dateRange = rootState.Range;
            if (todayOnly) dateRange = dateTimeManager.getToday();
            return axios.get(rootState.IP + "/sales/getDailySales/" + rootState.CurStore.StoreDB, {params: dateRange})
                .then(({data}) => commit("setCashCardTurn", data))
                .catch((err) => console.log(err));
        },


        getDashCashCardTurn: ({commit, rootState}) => {
            return axios.get(rootState.IP + "/sales/getDailySales/" + rootState.CurStore.StoreDB, {params: rootState.DayRange})
                .then(({data}) => commit("setDashCashCardTurn", data))
                .catch((err) => console.log(err));
        },

        getTodaySalesPerHour: ({commit, rootState}) => {
            return axios.get(rootState.IP + `/sales/getTodaySalesPerHour/` + rootState.CurStore.StoreDB, {params: rootState.DayRange})
                .then(({data}) => commit("setSalePerHour", data))
                .catch((err) => console.log(err));
        },

        getExpectedTurnover: ({commit, rootState}) => {
            return axios.get(rootState.IP + `/SaveTable/getExpectedTurnover/${rootState.CurStore.StoreDB}`)
                .then(({data}) => commit('setOpenTablesTotal', data))
                .catch((err) => console.log(err));
        },
    },
};
