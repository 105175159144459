import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default {
    name: 'Finance',
    state: {
        CashOnHand: {},
        Cash: [],
        BankOnHand: {},
        Bank: [],
        AllPayOuts: [],
        payoutFromTill: [],
        payoutFromStore: [],
        PayOut: [],
        Transactions: [],
        CashSafes: [],
        CashSafeTotal: 0.00,
        BankSafes: [],
        BankSafeTotal: 0.00,
        ChequeSafes: [],
        ChequeSafeTotal: 0.00,
        SafeLedger: [],
        ledgerTotal: 0.00
    },
    getters: {
        getTransactions: (state) => {
            return state.Transactions;
        },
        getCashIn: (state) => {
            return state.Cash;
        },
        getCashOut: (state) => {
            return state.Cash;
        },
        getBankIn: (state) => {
            return state.Bank;
        },
        getBankOut: (state) => {
            return state.Bank;
        },
        getPayOuts: (state) => {
            return state.AllPayOuts;
        },
        getPayOutsTill: (state) => {
            return state.payoutFromTill;
        },
        getPayOutsStore: (state) => {
            return state.payoutFromStore;
        },
        getPayOutSupplier: (state) => {
            return state.PayOut;
        },
        getPayOutGrv: (state) => {
            return state.PayOut;
        },
        getPayOutDep: (state) => {
            return state.PayOut;
        },
        getCashTotal: (state) => {
            return state.CashSafeTotal;
        },
        getBankTotal: (state) => {
            return state.BankSafeTotal;
        },
        getChequeTotal: (state) => {
            return state.ChequeSafeTotal;
        },
        getSafeByType: (state, getters) => (type) => {
            let Cash = state.CashSafes;
            let Bank = state.BankSafes;
            let Cheque = state.ChequeSafes;
            if (type === 'Cash') {
                console.log('Cash');
                return Cash;
            } else if (type === 'Bank') {
                console.log('Bank');
                return Bank;
            } else if (type === 'Cheque') {
                console.log('Cheque');
                return Cheque;
            } else if (type === 'All') {
                return [...Cash, ...Bank, ...Cheque];
            }
        },
        getSafeTotalByType: (state, getters) => (type) => {
            let Cash = state.CashSafeTotal;
            let Bank = state.BankSafeTotal;
            let Cheque = state.ChequeSafeTotal;
            if (type === 'Cash') {
                console.log('Cash_T');
                return Cash;
            } else if (type === 'Bank') {
                console.log('Bank_T');
                return Bank;
            } else if (type === 'Cheque') {
                console.log('Cheque_T');
                return Cheque;
            } else if (type === 'All') {
                console.log('All_T');
                return Cash + Bank + Cheque;
            }
        },
        getSafeName: (state, getters) => (id) => {
            let Cash = state.CashSafeTotal;
            let Bank = state.BankSafeTotal;
            let Cheque = state.ChequeSafeTotal;
            let Arr = [...Cash, ...Bank, ...Cheque];
            let Obj = Arr.find(function (safe) {
                return safe.id === id
            })
        },
        getSafeLed: (state) => {
            return state.SafeLedger;
        },
        getSafeLedTotal: (state) => {
            console.log(state.ledgerTotal + "SafeTotal");
            return state.ledgerTotal;
        }
    },
    mutations: {
        setCashIn: (state, data) => {
            state.Transactions = data
        },
        setCashOut: (state, data) => {
            state.Transactions = data
        },
        setBankIn: (state, data) => {
            state.Transactions = data
        },
        setBankOut: (state, data) => {
            state.Transactions = data
        },
        setPayOuts: (state, payload) => {
            let f = payload.f;
            if (f === true) {
                state.AllPayOuts = payload.data
            } else {
                state.AllPayOuts = state.AllPayOuts.concat(payload.data);
            }
        },
        setPayOutTill: (state, payload) => {
            let f = payload.f;
            if (f === true) {
                state.payoutFromTill = payload.data
            } else {
                state.payoutFromTill = state.payoutFromTill.concat(payload.data);
            }

        },
        setPayOutStore: (state, payload) => {
            let f = payload.f;
            if (f === true) {
                state.payoutFromStore = payload.data
            } else {
                state.payoutFromStore = state.payoutFromStore.concat(payload.data);
            }

        },
        setPayOutSupplier: (state, payload) => {
            let f = payload.f;
            if (f === true) {
                state.PayOut = payload.data
            } else {
                state.PayOut = state.PayOut.concat(payload.data);
            }

        },
        setPayOutGrv: (state, payload) => {
            let f = payload.f;
            if (f === true) {
                state.PayOut = payload.data
            } else {
                state.PayOut = state.PayOut.concat(payload.data);
            }

        },
        setPayOutDep: (state, payload) => {
            let f = payload.f;
            if (f === true) {
                state.PayOut = payload.data
            } else {
                state.PayOut = state.PayOut.concat(payload.data);
            }

        },
        setCashSafe: (state, data) => {
            let O_Arr = [];
            for (let i = 0; i < data.length; i++) {
                O_Arr[i] = {
                    id: data[i].id,
                    chartname: data[i].ChartName
                }
            }
            state.CashSafes = O_Arr;
        },
        setCashTotal: (state, data) => {
            state.CashSafeTotal = data;
        },
        setBankSafe: (state, data) => {
            state.BankSafes = data
        },
        setBankTotal: (state, data) => {
            state.BankSafeTotal = data;
        },
        setChequeSafe: (state, data) => {
            state.ChequeSafes = data
        },
        setChequeTotal: (state, data) => {
            state.ChequeSafeTotal = data;
        },
        setSafeLedger: (state, payload) => {
            let first = payload.f;
            let data = payload.d;
            if (first === true) {
                state.SafeLedger = data;
            } else {
                let a = state.SafeLedger.concat(data);
                state.SafeLedger = a;
            }
        },
        setSafeLedgerTotal: (state, data) => {
            state.ledgerTotal = data;
        },
        clearSLedger: (state) => {
            state.SafeLedger = [];
            state.ledgerTotal = 0.00;
        }

    },
    actions: {
        getCashIn: ({commit, rootState}, date) => {
            return axios
                .get(rootState.IP + "/finance/getCashIn/" + rootState.CurStore.StoreDB, {
                    params: date,
                })
                .then((res) => {
                    commit("setCashIn", res.data);
                })
                .catch((err) => console.log(err));
        },
        getCashOut:
            ({commit, rootState}, date) => {
                return axios
                    .get(rootState.IP + "/finance/getCashOut/" + rootState.CurStore.StoreDB, {
                        params: date,
                    })
                    .then((res) => {
                        commit("setCashOut", res.data);
                    })
                    .catch((err) => console.log(err));
            },
        getBankIn:
            ({commit, rootState}, date) => {
                return axios
                    .get(rootState.IP + "/finance/getBankIn/" + rootState.CurStore.StoreDB, {
                        params: date,
                    })
                    .then((res) => {
                        commit("setBankIn", res.data);
                    })
                    .catch((err) => console.log(err));
            },
        getBankOut:
            ({commit, rootState}, date) => {
                return axios
                    .get(rootState.IP + "/finance/getBankOut/" + rootState.CurStore.StoreDB, {
                        params: date,
                    })
                    .then((res) => {
                        commit("setBankOut", res.data);
                    })
                    .catch((err) => console.log(err));
            },
        getAllPayOuts:
            ({commit, rootState}, data) => {
                return axios
                    .get(
                        rootState.IP + "/finance/getAllPayOuts/" + rootState.CurStore.StoreDB, {
                            params: data,
                        }
                    )
                    .then((res) => {
                        let payload = {'data': res.data, 'f': data.first};
                        commit("setPayOuts", payload);
                    })
                    .catch((err) => console.log(err));
            },
        getPayOutTill:
            ({commit, rootState}, data) => {
                return axios
                    .get(rootState.IP + "/finance/getPayOutPos/" + rootState.CurStore.StoreDB, {
                        params: data,
                    })
                    .then((res) => {
                        let payload = {'data': res.data, 'f': data.first};
                        commit("setPayOutTill", payload);
                    })
                    .catch((err) => console.log(err));
            },
        getPayOutStore:
            ({commit, rootState}, data) => {
                return axios
                    .get(
                        rootState.IP + "/finance/getPayOutStore/" + rootState.CurStore.StoreDB, {
                            params: data,
                        }
                    )
                    .then((res) => {
                        let payload = {'data': res.data, 'f': data.first};
                        commit("setPayOutStore", payload);
                    })
                    .catch((err) => console.log(err));
            },
        getPayOutSupplier:
            ({commit, rootState}, data) => {
                return axios
                    .get(
                        rootState.IP + "/finance/getPayOutSupplier/" + rootState.CurStore.StoreDB, {
                            params: rootState.Range,
                        }
                    )
                    .then((res) => {
                        let payload = {'data': res.data, 'f': data.first};
                        commit("setPayOutSupplier", payload);
                    })
                    .catch((err) => console.log(err));
            },
        getPayOutGrv:
            ({commit, rootState}, data) => {
                return axios
                    .get(rootState.IP + "/finance/getPayOutGrv/" + rootState.CurStore.StoreDB, {
                        params: rootState.Range,
                    })
                    .then((res) => {
                        let payload = {'data': res.data, 'f': data.first};
                        commit("setPayOutGrv", res.data);
                    })
                    .catch((err) => console.log(err));
            },
        getPayOutDep:
            ({commit, rootState}, data) => {
                return axios
                    .get(rootState.IP + "/finance/getPayOutDep/" + rootState.CurStore.StoreDB, {
                        params: rootState.Range,
                    })
                    .then((res) => {
                        let payload = {'data': res.data, 'f': data.first};
                        commit("setPayOutDep", payload);
                    })
                    .catch((err) => console.log(err));
            },
        getAllSafes: (context) => {
            return axios.all([
                context.dispatch('getCashSafes'),
                context.dispatch('getBankSafes'),
                context.dispatch('getChequeSafes'),
            ]);
        },
        getCashSafes: ({commit, rootState}) => {
            return axios.get(rootState.IP + "/finance/getAllSafes/" + rootState.CurStore.StoreDB)
                .then(({data}) => commit("setCashSafe", data))
                .catch((err) => console.log(err));

        },
        getBankSafes: ({commit, rootState}) => {
            return axios.get(rootState.IP + "/finance/getAllBank/" + rootState.CurStore.StoreDB)
                .then(({data}) => commit("setBankSafe", data))
                .catch((err) => console.log(err));

        },
        getChequeSafes: ({commit, rootState}) => {
            return axios.get(rootState.IP + "/finance/getAllCheques/" + rootState.CurStore.StoreDB)
                .then((res) => commit("setChequeSafe", res.data))
                .catch((err) => console.log(err));
        },
        getCashSafeTotal: ({rootState, commit}) => {
            return axios.get(rootState.IP + "/finance/getSafeTotals/CASH/" + rootState.CurStore.StoreDB)
                .then((res) => commit("setCashTotal", res.data))
                .catch((err) => reject(err));
        },
        getBankSafeTotal:
            ({rootState, commit}) => {
                return new Promise((resolve, reject) => {
                    return new Promise((resolve, reject) => {
                        return axios
                            .get(rootState.IP + "/finance/getSafeTotals/BANK/" + rootState.CurStore.StoreDB)
                            .then((res) => {
                                commit("setBankTotal", res.data);
                                resolve("success");
                            })
                            .catch((err) => reject(err));
                    });
                });
            },
        getChequeSafeTotal: ({rootState, commit}) => {
            return new Promise((resolve, reject) => {
                return axios
                    .get(rootState.IP + "/finance/getSafeTotals/CHEQUE/" + rootState.CurStore.StoreDB)
                    .then((res) => {
                        commit("setChequeTotal", res.data);
                        resolve('success');
                    })
                    .catch((err) => reject(err));
            });
        },
        getSafeLedger: ({rootState, commit}, data) => {
            return axios.get(rootState.IP + `/finance/getSafeLedger/${rootState.CurStore.StoreDB}`, {
                params: data,
            })
                .then((res) => {
                    const payload = {'d': res.data[0], 'f': data.first};
                    commit("setSafeLedger", payload);
                    commit("setSafeLedgerTotal", res.data[1]);
                })
                .catch((err) => console.log(err));
        },
    }
};
