<template>
  <div id="app">
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"
             :blur="'10px'"
             :color="color"
             :background-color="backgroundColor"
             :lock-scroll="lockScroll"
             :enforce-focus="true">
    </loading>

    <router-view/>
  </div>
</template>
<script>

import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'app',
  components : {Loading},
  beforeMount() {
    if (this.$route.name !== 'login'){
      this.$store.commit('SetCurStore')
      this.$store.commit('setStores')
    }
  },
  computed : {
    isLoading(){
      return this.$store.getters.getLoading;
    }
  },
  data(){
    return {
      fullPage: true,
      color: '#0497e0',
      backgroundColor: '#000',
      blur: '5px',
      lockScroll: true
    }
  },
}
</script>
<style lang="scss">
@import "assets/css/animate.css";
@import "assets/css/style.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,700;1,300&display=swap');

* {
  box-sizing: border-box;

}

.mx-datepicker {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100% !important;
  margin-bottom: 10px;
}

#app {
  font-family: "Roboto Light", sans-serif;
  user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  overflow: hidden !important;
  width: 100vw;
}

#app > div > div.content > div.row > div > div > div > div > div > div > div > ul > li.form-group > div > div > input {
  width: 100%;
  height: 40px;
  padding: 6px 40px 6px 30px;
  font-size: 14px;
  line-height: 1.4;
  color: #555;
  font-weight: 600;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 2em !important;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
}

html, body {
  margin: 0;
  padding: 0;
  max-width: 100vw;
  min-height: 100vh;
  overflow-x: hidden !important;
}
</style>
