import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default {
    name: "Creditors",

    state: {
        suppPaid: 0,
        suppBought: 0,
        suppBalance: 0,
        suppTransactions: [],
        suppInvoices: [],
        suppCreditNotes: [],
        suppPayments: [],
        suppGRVs: [],
        suppSearch: [],
        Supplier: {},
    },
    getters: {
        getSuppSearch: store => {
            return store.suppSearch
        },
        getSupplier: state => {
            return state.Supplier;
        },
        getSuppInvoices: (state) => {
            return state.suppInvoices
        },
        getSuppCreditNotes: (state) => {
            return state.suppCreditNotes
        },
        getSuppPayments: (state) => {
            return state.suppPayments
        },
        getSuppGRVs: (state) => {
            return state.suppPayments
        },
        getSPaid: (state) => {
            return state.suppPaid
        },
        getSBought: (state) => {
            return state.suppBought
        },
        getSBalance: (state) => {
            return state.suppBalance
        },
        getSTransactions: (state) => {
            return state.suppTransactions
        },
    },
    mutations: {
        setSuppSearch: (state, payload) => {
            if (payload.f === true) {
                state.suppSearch = payload.d
            } else {
                state.suppSearch = state.suppSearch.concat(payload.d);
            }
        }, setSupplier: (state, payload) => {
            state.Supplier = payload
        }, setSuppInvoices: (state, payload) => {
            if (payload.f === true) {
                state.suppInvoices = payload.d
            } else {
                state.suppInvoices = state.suppInvoices.concat(payload.d);
            }
        }, setSuppCreditNotes: (state, payload) => {
            if (payload.f === true) {
                state.suppCreditNotes = payload.d
            } else {
                state.suppCreditNotes = state.suppCreditNotes.concat(payload.d);
            }
        }, setSuppPayments: (state, payload) => {
            let first = payload.f;
            let data = payload.d;
            if (payload.f === true) {
                state.suppPayments = payload.d
            } else {
                state.suppPayments = state.suppPayments.concat(payload.d);
            }
        }, setSuppGRVs: (state, payload) => {
            if (payload.f === true) {
                state.suppPayments = payload.d
            } else {
                state.suppPayments = state.suppPayments.concat(payload.d);
            }
        }, setSPaid: (state, payload) => {
            state.suppPaid = payload
        }, setSBought: (state, payload) => {
            state.suppBought = payload

        }, setSBalance: (state, payload) => {
            state.suppBalance = payload
        }, setSTransactions: (state, payload) => {
            if (payload.f === true) {
                state.suppTransactions = payload.d
            } else {
                state.suppTransactions = state.suppTransactions.concat(payload.d);
            }
        },
    },
    actions: {
        SuppSearch: ({state, commit, rootState}, data) => {
            return new Promise(((resolve, reject) => {
                return axios
                    .get(
                        rootState.IP +
                        "/Supplier/searchSupplier/" +
                        rootState.CurStore.StoreDB,
                        {
                            params: data,
                        }
                    )
                    .then((res) => {
                        let payload = {'f': data.first, 'd': res.data};
                        commit("setSuppSearch", payload);
                        resolve('success');
                    })
                    .catch((err) => {
                        reject(err);
                        console.log(err)
                    });
            }))
        },
        getSupplier: ({state, commit, rootState}, data) => {
            return new Promise(((resolve, reject) => {
                return axios
                    .get(`${rootState.IP}/Supplier/getSupplier/${data}/${rootState.CurStore.StoreDB}`)
                    .then((res) => {
                        commit("setSupplier", res.data);
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                        console.log(err);
                    });
            }))
        },
        getSuppInvoices: ({state, commit, rootState}, data) => {
            return new Promise(((resolve, reject) => {
                return axios
                    .get(`${rootState.IP}/Supplier/getSupInvoices/${data.id}/${rootState.CurStore.StoreDB}`)
                    .then((res) => {
                        let payload = {'f': data.first, 'd': res.data};
                        commit("setSuppInvoices", payload);
                        resolve('success');
                    })
                    .catch((err) => {
                        reject(err);
                        console.log(err)
                    });
            }))
        },
        getSuppCreditNotes: ({state, commit, rootState}, data) => {
            return new Promise(((resolve, reject) => {
                return axios
                    .get(`${rootState.IP}/Supplier/getSupReturns//${data.id}/${rootState.CurStore.StoreDB}`, {
                        params: data
                    })
                    .then((res) => {
                        let payload = {'f': data.first, 'd': res.data};
                        commit("setSuppCreditNotes", payload);
                        resolve('success');
                    })
                    .catch((err) => {
                        reject(err), console.log(err)
                    });
            }))
        },
        getSuppPayments: ({state, commit, rootState}, data) => {
            return new Promise(((resolve, reject) => {
                return axios
                    .get(`${rootState.IP}/Supplier/getSupPayments/${data.id}/${rootState.CurStore.StoreDB}`, {
                        params: data
                    })
                    .then((res) => {
                        let payload = {'f': data.first, 'd': res.data};
                        commit("setSuppPayments", payload);
                        resolve('success');
                    })
                    .catch((err) => {
                        reject(err), console.log(err)
                    });
            }))
        },
        getSuppGRVs: ({state, commit, rootState}, data) => {
            return new Promise(((resolve, reject) => {
                    return axios
                        .get(`${rootState.IP}/Supplier/getSupGRVs/${data.id}/${rootState.CurStore.StoreDB}`, {
                                params: data
                            }
                        )
                        .then((res) => {
                            let payload = {'f': data.first, 'd': res.data};
                            commit("setSuppGRVs", payload);
                            resolve('success');
                        })
                        .catch((err) => {
                            reject(err), console.log(err)
                        });
                }
            ))
        },
        getSPaid: ({state, commit, rootState}, data) => {
            return new Promise(((resolve, reject) => {
                return axios
                    .get(
                        rootState.IP +
                        "/Supplier/getAllDiscoutAcc/" +
                        rootState.CurStore.StoreDB,
                        {
                            params: {BF: state.BFDate},
                        }
                    )
                    .then((res) => {
                        commit("setSPaid", res.data);
                        resolve('success');
                    })
                    .catch((err) => {
                        reject(err), console.log(err)
                    });
            }))
        },
        getSBought: ({state, commit, rootState}, data) => {
            return new Promise(((resolve, reject) => {
                return axios
                    .get(
                        rootState.IP +
                        "/Supplier/getAllDiscoutAcc/" +
                        rootState.CurStore.StoreDB,
                        {
                            params: {BF: state.BFDate},
                        }
                    )
                    .then((res) => {
                        commit("setSBought", res.data);
                        resolve('success');
                    })
                    .catch((err) => {
                        reject(err), console.log(err)
                    });
            }))
        },
        getSBalance: ({state, commit, rootState}, data) => {
            return new Promise(((resolve, reject) => {
                return axios
                    .get(
                        rootState.IP +
                        "/Supplier/getBalance/" +
                        rootState.CurStore.StoreDB,
                        {
                            params: {id: data},
                        }
                    )
                    .then((res) => {
                        commit("setSBalance", res.data);
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err), console.log(err)
                    });
            }))
        },
        getSTransactions: ({state, commit, rootState}, data) => {
            return new Promise(((resolve, reject) => {
                return axios
                    .get(
                        rootState.IP +
                        "/Supplier/getAllDiscoutAcc/" +
                        rootState.CurStore.StoreDB,
                        {
                            params: {BF: state.BFDate},
                        }
                    )
                    .then((res) => {
                        let payload = {'f': data.first, 'd': res.data};
                        commit("setSTransactions", payload);
                        resolve('success');
                    })
                    .catch((err) => {
                        reject(err), console.log(err)
                    });
            }))
        },

    },
};
