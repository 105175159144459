import Vue, {onActivated} from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "../router";
import Finance from "./modules/Finance/Finance";
import Sales from "./modules/Sales/Sales";
import Stock from "./modules/Stock/Stock";
import Warning from "./modules/Warning";
import Audit from "./modules/Audit";
import Debtors from "./modules/DEB_CRED/Debtors/Accounts";
import CashUpModule from "./modules/Reports/CashUp/cashUpReports";
import Creditors from "./modules/DEB_CRED/Creditors/Creditors";
import Invoices from "./modules/Sales/Reports/invoices";
import Print from "@/store/modules/Print";
import Securities from "@/store/modules/Securities";
import emailModule from "@/store/modules/email-module"
import settingsModule from "@/store/modules/settings-module";

function REMEMBER(Data) {
    if (!Data) {
        return localStorage.removeItem(`remember`);
    }
    let data = {
        "username": Data.Username,
        "password": Data.Password
    };
    localStorage.setItem('remember', JSON.stringify(data))
}

Vue.use(Vuex);
/**
 * @typeof {Object} CurStore
 * @property {String} StoreName
 */

export const store = new Vuex.Store({
    modules: {
        Finance,
        Sales,
        Stock,
        Warning,
        Audit,
        Debtors,
        Creditors,
        CashUpModule,
        Invoices,
        Print,
        Securities,
        emailModule,
        settingsModule
    },
    state: {
        IP: "https://chrilantech.com/LytSoftPosOnline/api",
        //IP: "http://192.168.8.112:8000/api",
        user: {},
        Stores: [],
        CurStore: localStorage.getItem('current_store'),
        Turnover: {},
        Period: 5,
        Range: {
            from: "",
            to: ""
        },
        DayRange: {
            from: "",
            to: ""
        },
        MonthRange: {
            from: "",
            to: ""
        },
        TempDateChanges: {
            original: {},
            temp: {}
        },
        Online: false,
        loading: false,
        baseCurrency: {Symbol: `K`},
    },
    getters: {
        getStores: (state) => state.Stores,
        getOnline: (state) => state.Online,
        getCurStore: () => JSON.parse(localStorage.getItem('current_store')),
        getTempDate: (state) => state.TempDateChanges,
        getStoreName: (state) => !(!state.CurStore || !state.CurStore.StoreName),
        getUser: (state) => state.user,
        getMonthRange: state => state.MonthRange,
        getLoading: (state) => state.loading,
        getCurrentRange: (state) => state.Range,
        getBaseCurrency: (state) => state.baseCurrency,
    },
    mutations: {
        SET_REMEMBER: (state, data) => REMEMBER(data),
        setBaseCurrency: async (state, payload) => {
            if (payload) localStorage.setItem(`baseCurrency`, payload);
            else localStorage.getItem('baseCurrency')
            state.baseCurrency = payload
        },
        setUser: (state, data) => {
            state.user = data;
            return true;
        },
        setStores: (state, data) => {
            if (!data) {
                data = JSON.parse(localStorage.getItem('allStores'))
            } else {
                localStorage.setItem('allStores', JSON.stringify(data))
            }
            state.Stores = data;
        },

        SetCurStore: (state, data) => {
            if (!data) {
                data = JSON.parse(localStorage.getItem('current_store'))
            } else {
                localStorage.setItem('current_store', JSON.stringify(data))
            }
            state.CurStore = data
        },
        setAutoLogin: () => localStorage.setItem(`autoLogin`, JSON.stringify(true)),
        removeAutoLogin: () => localStorage.removeItem(`autoLogin`),
        clearCurrentStore: () => {
            let user = localStorage.getItem('remember');
            resetState();
            localStorage.clear();
            if (user) localStorage.setItem('remember', user);
        },
        tempChange: (state, payload) => state.TempDateChanges.temp = payload,
        Start: (state, data) => state.Range = data,
        DayStart: (state, data) => state.DayRange = data,
        StartMonth: (state, data) => state.MonthRange = data,
        setLoading: (state, data) => state.loading = data,
        setPeriod: (state, data) => state.Period = data
    },
    actions: {
        syncData(context) {
            return Promise.all([
                context.dispatch(`getBaseCurrency`)
            ]);
        },
        getBaseCurrency: async (context) => {
            let store = context.state.CurStore;
            if (!store) return;
            return axios.get(franchiseTomcatApi + `currency/${store.StoreDB}/getBaseCurrency`)
                .then(({data}) => context.commit('setBaseCurrency', data))
                .catch((err) => errorHandler.tomcatError(err))
        },

        Login: ({commit, state}, inputData) => {
            return axios.post(state.IP + '/user/newlogin', inputData)
                .then(({data}) => {
                    commit("setUser", inputData);
                    commit("setStores", data);

                    if (inputData.loginToStore) {
                        let store = data.find(x => x.StoreDB === inputData.email);
                        if (store) {
                            let storeData = {
                                StoreID: store.id,
                                StoreName: store.StoreName,
                                StoreDB: store.StoreDB,
                                GroupID: store.GroupID,
                            };
                            commit("SetCurStore", storeData);
                            return router.push({name: 'home'});
                        }
                    }
                    return true;
                }).catch((err) => {
                    console.error(err);
                    commit("SET_REMEMBER", null);
                    return this.$alert(
                        "error",
                        "User Not Found!",
                        "incorrect Username or Password"
                    );
                });
        },
        SignOut: ({commit}) => {
            resetState();
            localStorage.clear();
            return router.push({name: 'login'});
        },
        getOnline({state}) {
            return axios.get(state.IP + "/online/getOnline/" + state.CurStore.StoreDB)
                .then((res) => state.Online = res.data.online)
                .catch((err) => {
                    state.Online = false;
                    console.log(err);
                });
        },
        GetStores: ({state, commit}, data) => {
            return axios.get(state.IP + "/user/getStores/" + data)
                .then((res) => {
                    commit("setStores", res.data);
                })
                .catch((err) => console.log(err));
        },
        getStoreData: ({dispatch}) => {
            return axios.all([
                dispatch("getDashCashCardTurn"),
                dispatch("getAllDebtorBalance"),
                dispatch("getCashSafeTotal"),
                dispatch("getOnline"),
            ]).catch(() => this.$toast('error', 'error getting store data', 'center'));
        },
        getSetting: ({state, commit}) => {
            return axios.get(state.IP + `/Settings/getAll/${state.CurStore.StoreDB}`)
                .then(({data}) => commit("setPeriod", data[0].PeriodStart))
                .catch(err => console.log(err));
        },

        getAllPCs: (context) => {
            let storeDB = context.state.CurStore.StoreDB;
            return axios.get(context.state.IP + `/Settings/getAllPCs/${storeDB}`)
                .then(({data}) => data)
                .catch(err => console.log(err));
        },

        OTPLogin: (context, payload) => {
            return axios.get(franchiseTomcatApi + `stores/useStoreOTP`, {
                params: {otp: payload}
            }).then(async ({data}) => {
                context.commit("SetCurStore", data);
                await axios.get(franchiseTomcatApi + `stores/getByGroup`, {
                    params: {group: data.Group}
                }).then(res => context.commit("setStores", res.data))
                return router.push({name: 'home'});
            }).catch(err => errorHandler.tomcatError(err))
        }

    },
});
const initialStateCopy = JSON.parse(JSON.stringify(store.state));

export function resetState() {
    store.replaceState(JSON.parse(JSON.stringify(initialStateCopy)))
}
